import React from "react"
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Checkout from "../components/checkout"


const PaymentPage = ({location}) => {
  // eslint-disable-next-line no-restricted-globals
  const query =
  (location && location.pathname) ||
  location.pathname.replace(/^\/payment\/?/, '') ||
  '';
  const sku = query.replace(/^\/payment\/?/, '');


  return (
  <Layout>
    <SEO title="Home" />
    <p>Please click on the button to proceed to the payment.</p>
    <Checkout sku={sku} />
  </Layout>
)}

export default PaymentPage
