import React ,{useState, useEffect } from "react"
const { GATSBY_STRIPE_API_KEY } = process.env

const buttonStyles = {
  fontSize: "13px",
  textAlign: "center",
//   color: "#fff",
  outline: "none",
  padding: "12px 60px",
  boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
//   backgroundColor: "rgb(255, 178, 56)",
  borderRadius: "6px",
  letterSpacing: "1.5px",
}

const Checkout = ({ sku }) => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    (async () => {
      const obj = window.Stripe(GATSBY_STRIPE_API_KEY);
      setStripe(obj);
    })();
  }, []);

  const redirectToCheckout = event => {
    event.preventDefault()
    const { error } = stripe.redirectToCheckout({
      items: [{ sku: sku, quantity: 1 }],
      successUrl: `https://agatajuliamakeup.com/payment-successful`,
      cancelUrl: `https://payment.agatajuliamakeup.com/payment/${sku}`,
    })

    if (error) {
      console.warn("Error:", error)
    }
  }
  return (
    <div>
      <button
        style={buttonStyles}
        onClick={redirectToCheckout}
      >
        Proceed to secure payment
      </button>
    </div>
  )
}

export default Checkout
